@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;700&display=swap");
/* #region  ACCENTS */
:root {
  --c-accent: #5498a3;
  --c-accent-d: #437981;
  --c-accent-inv: #fff;
}

[data-section=ricerca] {
  --c-accent: #27a536;
  --c-accent-d: #1d7c29;
  --c-accent-inv: #fff;
}

[data-section=consulenza] {
  --c-accent: #0071b9;
  --c-accent-d: #005286;
  --c-accent-inv: #fff;
}

[data-section=formazione] {
  --c-accent: #fab600;
  --c-accent-d: #c79100;
  --c-accent-inv: #fff;
}

[data-section=news] {
  --c-accent: #e73b2b;
  --c-accent-d: #c82617;
  --c-accent-inv: #fff;
}

h1 {
  color: var(--c-accent) !important;
}

a {
  color: var(--c-accent);
}

.text-blueish {
  color: #5498a3 !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
}

.accent-color {
  color: var(--c-accent) !important;
}

.accent-bg {
  background-color: var(--c-accent) !important;
}

@media screen and (max-width: 731px) {
  .circle-quarter {
    top: -6rem;
  }
}
.circle-quarter svg * {
  fill: var(--c-accent);
}

/* #endregion */
.color-grey {
  color: #ececec;
}

.bg-grey {
  background-color: #ececec;
}

a.color-grey:hover {
  color: lightgray;
}

.color-blueish {
  color: #5498a3;
}

.bg-blueish {
  background-color: #5498a3;
}

a.color-blueish:hover {
  color: #437981;
}

.color-red {
  color: #e73b2b;
}

.bg-red {
  background-color: #e73b2b;
}

a.color-red:hover {
  color: #c82617;
}

.color-green {
  color: #27a536;
}

.bg-green {
  background-color: #27a536;
}

a.color-green:hover {
  color: #1d7c29;
}

.color-yellow {
  color: #fab600;
}

.bg-yellow {
  background-color: #fab600;
}

a.color-yellow:hover {
  color: #c79100;
}

.color-black {
  color: #494949;
}

.bg-black {
  background-color: #494949;
}

a.color-black:hover {
  color: #303030;
}

.extend-fa, .slider .slider-navigation .swiper-button-prev::after,
.slider .slider-navigation .swiper-button-next::after, .read-more::before, .btn.btn-action::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

:root {
  --size-h1: 3rem;
  --size-h2: 2rem;
  --size-h3: 2rem;
  --size-h4: 1.5rem;
  --size-h5: 1.3rem;
  --section-padding: 7rem;
  --bs-font-sans-serif: "Red Hat Display", sans-serif;
}
@media screen and (max-width: 731px) {
  :root {
    --size-h1: 1.7rem;
    --size-h2: 1.5rem;
    --size-h3: 1.5rem;
    --size-h4: 1.3rem;
    --size-h5: 1rem;
    --section-padding: 3rem;
  }
}

html {
  font-size: 1.2rem;
  font-family: "Red Hat Display", sans-serif;
}

body {
  line-height: 150%;
}

h1,
.h1 {
  font-size: var(--size-h1);
  font-weight: bold;
  color: #5498a3;
  text-transform: uppercase;
}
h1.size-h2,
.h1.size-h2 {
  font-size: var(--size-h2);
}

h2,
.h2 {
  font-size: var(--size-h2);
  font-weight: bold;
  color: #494949;
}

h3,
.h3 {
  font-size: var(--size-h3);
  font-weight: 300;
  color: #494949;
}

h4,
.h4 {
  font-size: var(--size-h4);
  font-weight: 300;
  color: #494949;
  margin-bottom: 0;
}
h4:not(:first-child),
.h4:not(:first-child) {
  margin-top: 2rem;
}

h5,
.h5 {
  font-size: var(--size-h5);
  color: #494949;
}

a {
  color: #5498a3;
}

.bigger {
  font-size: 1.5em;
  line-height: 1.5;
}
@media screen and (max-width: 1200px) {
  .bigger {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 731px) {
  .bigger {
    font-size: 1rem;
  }
}

.p-big {
  font-size: 1.2rem;
  color: #939393;
}
@media screen and (max-width: 731px) {
  .p-big {
    font-size: 1.1rem;
  }
}
.p-big p {
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) and (min-width: 731px) {
  .container {
    max-width: 96vw;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1400px;
  }
}
@media screen and (min-width: 1680px) {
  .container {
    max-width: 1600px;
  }
}

.btn {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.btn.btn-primary {
  background-color: #5498a3;
  color: #fff;
  border-color: #fff;
}
.btn.btn-action::before {
  content: "\f061";
  padding-right: 1rem;
  transition-duration: 0.3s;
}
.btn.btn-action:hover::before {
  transform: translateX(0.5rem);
}
.btn.btn-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

nav.navbar {
  position: sticky;
  left: 0;
  background-color: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(10px);
  top: 0;
  width: 100%;
  padding: 0px 2vw;
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.05);
  --h: 4rem;
}
@media screen and (max-width: 731px) {
  nav.navbar {
    top: 0;
    padding-right: 0;
    padding-left: 0;
    background-color: #fff;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  nav.navbar {
    --h: 3rem;
  }
}
@media screen and (max-width: 731px) {
  nav.navbar {
    --h: 2.5rem;
  }
}
nav.navbar .navbar-brand {
  padding: 0;
  height: var(--h);
  display: flex;
  align-items: center;
}
nav.navbar .navbar-brand img {
  height: 60%;
}
nav.navbar .navbar-toggler {
  border: 0px;
  outline: 0;
  padding: 0;
}
nav.navbar .navbar-toggler:focus {
  box-shadow: none;
}
nav.navbar .navbar-toggler .icon {
  width: 25px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
nav.navbar .navbar-toggler .icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #494949;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
nav.navbar .navbar-toggler .icon span:nth-child(1) {
  top: 0px;
}
nav.navbar .navbar-toggler .icon span:nth-child(2) {
  top: 10px;
}
nav.navbar .navbar-toggler .icon span:nth-child(3) {
  top: 10px;
}
nav.navbar .navbar-toggler .icon span:nth-child(4) {
  top: 20px;
}
nav.navbar .navbar-toggler:not(.collapsed) span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}
nav.navbar .navbar-toggler:not(.collapsed) span:nth-child(2) {
  transform: rotate(45deg);
}
nav.navbar .navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: rotate(-45deg);
}
nav.navbar .navbar-toggler:not(.collapsed) span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}
@media screen and (max-width: 731px) {
  nav.navbar .navbar-nav {
    margin-bottom: 10px;
  }
}
nav.navbar .navbar-nav .nav-item {
  height: var(--h);
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 731px) {
  nav.navbar .navbar-nav .nav-item {
    justify-content: center;
    height: auto;
    display: block;
  }
}
nav.navbar .navbar-nav .nav-item::after {
  content: "";
  display: block;
  width: 100%;
  height: 0px;
  background-color: #5498a3;
  position: absolute;
  bottom: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: 0.9;
  z-index: -1;
}
nav.navbar .navbar-nav .nav-item.accent-green::after {
  background-color: #27a536;
}
nav.navbar .navbar-nav .nav-item.accent-yellow::after {
  background-color: #fab600;
}
nav.navbar .navbar-nav .nav-item.accent-blue::after {
  background-color: #0071b9;
}
nav.navbar .navbar-nav .nav-item.accent-red::after {
  background-color: #e73b2b;
}
@media screen and (min-width: 991px) {
  nav.navbar .navbar-nav .nav-item:hover::after {
    height: 10px;
  }
  nav.navbar .navbar-nav .nav-item.current-menu-item::after {
    height: 5px;
  }
}
nav.navbar .navbar-nav .nav-item .nav-link {
  font-size: 0.9rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-weight: 500;
  color: #494949;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 1400px) {
  nav.navbar .navbar-nav .nav-item .nav-link {
    padding-left: 0.33rem;
    padding-right: 0.33rem;
  }
}
@media screen and (max-width: 731px) {
  nav.navbar .navbar-nav .nav-item .nav-link:focus {
    padding-left: 0;
    padding-right: 0;
    color: #5498a3 !important;
  }
}
@media screen and (min-width: 731px) {
  nav.navbar .navbar-nav .nav-item .nav-link.dropdown-toggle::after {
    content: none;
  }
}
nav.navbar .lang-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
nav.navbar .lang-switch .dropdown .dropdown-toggle {
  text-transform: uppercase;
  color: #494949;
  border: 1px solid #cdcdcd;
  background-color: #efefef;
  border-radius: 6px;
  padding: 6px;
  text-align: center;
  font-size: 0.8rem;
  width: 2rem;
}
nav.navbar .lang-switch .dropdown .dropdown-toggle.show {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
nav.navbar .lang-switch .dropdown .dropdown-toggle::after {
  content: none;
}
nav.navbar .lang-switch .dropdown .dropdown-menu {
  min-width: unset;
  text-transform: uppercase;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  font-size: 0.8rem;
  margin-top: 0;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 2rem;
}
nav.navbar .lang-switch .dropdown .dropdown-menu > li > a.dropdown-item {
  text-align: center;
  padding: 0;
  text-transform: uppercase;
  padding: 0;
  color: #494949;
}
nav.navbar .lang-switch .dropdown .dropdown-menu > li > a.dropdown-item:focus {
  background-color: #ccc;
}
nav.navbar .navbar-colors {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 5px;
  opacity: 0;
  transition-duration: 200ms;
}
@media screen and (max-width: 731px) {
  nav.navbar .navbar-colors {
    display: flex;
  }
}
nav.navbar .navbar-colors .y,
nav.navbar .navbar-colors .b,
nav.navbar .navbar-colors .r,
nav.navbar .navbar-colors .g {
  flex: 1 0 0%;
}
nav.navbar .navbar-colors .y {
  background-color: #fab600;
}
nav.navbar .navbar-colors .b {
  background-color: #0071b9;
}
nav.navbar .navbar-colors .r {
  background-color: #e73b2b;
}
nav.navbar .navbar-colors .g {
  background-color: #27a536;
}
nav.navbar .navbar-collapse {
  position: relative;
}
@media screen and (max-width: 731px) {
  nav.navbar .navbar-collapse {
    margin-top: 1rem;
  }
}
nav.navbar .navbar-collapse.show {
  border: 1px solid;
}
@media screen and (max-width: 731px) {
  nav.navbar .navbar-collapse.show {
    border: 0;
  }
}
nav.navbar .navbar-collapse.show .navbar-colors {
  opacity: 1;
}

.box {
  padding: 1rem;
  background: #f9f9f9;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  border: 2px solid #ececec;
}
.box h3 {
  margin-bottom: 2rem;
}
.box h4 {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.box h5 {
  transition-duration: 0.3s;
}
.box h5 a {
  color: inherit;
  text-decoration: none !important;
}
.box h5 .fa-arrow-right {
  margin-right: 1rem;
  transition-duration: 0.3s;
}
.box:not(.box-vertical-slider) h5:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.box:not(.box-vertical-slider) h5:hover a {
  text-decoration: none !important;
}
.box:not(.box-vertical-slider) h5:hover .fa-arrow-right {
  transform: translateX(0.5rem);
}
.box.box-vertical-slider {
  padding: 2rem 1rem;
  background: #f9f9f9;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  border: 2px solid #ececec;
}
.box.box-vertical-slider .box-title {
  text-transform: uppercase;
}
.box.box-vertical-slider .splide__slide {
  padding: 1rem;
  padding-right: 2rem;
}
@media screen and (max-width: 731px) {
  .box.box-vertical-slider .splide__slide {
    padding-right: 0;
    padding: 0;
  }
}
.box.box-vertical-slider .splide__slide .fa-arrow-right {
  margin-right: 1rem;
  transition-duration: 0.3s;
}
.box.box-vertical-slider .splide__slide:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.box.box-vertical-slider .splide__slide:hover .fa-arrow-right {
  transform: translateX(0.5rem);
}
.box.box-vertical-slider .splide__pagination__page {
  background: #fff;
  border: 1px solid #494949;
}
.box.box-vertical-slider .splide__pagination__page.is-active {
  background: #494949;
}

.read-more {
  display: inline-block;
  text-decoration: none;
  width: fit-content;
  font-size: 1.3rem;
  font-weight: 600;
}
.read-more::before {
  content: "\f061";
  padding-right: 1.5rem;
  transition-duration: 0.3s;
}
@media screen and (max-width: 1200px) {
  .read-more {
    font-size: 1rem;
  }
}
@media screen and (max-width: 731px) {
  .read-more {
    font-size: 1.1rem;
  }
  .read-more::before {
    padding-right: 1rem;
  }
}
.read-more:hover::before {
  transform: translateX(0.5rem);
}

img.backlogo {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.goto-archive-link {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  color: #5498a3;
  font-size: 0.9rem;
}
.goto-archive-link:hover {
  color: #437981;
}

.goto-submission-link {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  color: #939393;
  font-size: 0.9rem;
}
.goto-submission-link:hover {
  color: #7a7a7a;
}

.alignleft {
  float: left;
}

.slider {
  --swiper-navigation-size: 20px;
}
@media screen and (max-width: 731px) {
  .slider .slide-elem {
    padding: 0 15px;
  }
}
.slider .slide-elem > a {
  color: inherit;
  text-decoration: none;
}
.slider .slide-elem .featured-image {
  width: 100%;
  padding-top: 57%;
  position: relative;
  background-color: #000;
}
.slider .slide-elem .featured-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition-duration: 0.3s;
}
.slider .slide-elem h5 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: var(--size-h5);
  font-weight: bold;
}
.slider .slide-elem .title-line {
  height: 5px;
  width: 10rem;
  margin-bottom: 1rem;
}
.slider .slide-elem .read-more::before {
  transition-duration: 0.3s;
}
.slider .slide-elem:hover .featured-image img {
  opacity: 0.75;
}
.slider .slide-elem:hover .read-more::before {
  transform: translateX(0.5rem);
}
.slider .slide-elem.goto-archive .featured-image {
  background-color: #ececec;
}
.slider .slide-elem.goto-archive-slide {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slider .slide-elem.goto-archive-slide > a {
  display: block;
  width: 10rem;
}
.slider .slide-elem.goto-archive-slide > a .arrow {
  width: 7rem;
  height: 7rem;
  position: relative;
  border-radius: 50%;
  background-color: #fff;
  margin: auto;
  margin-bottom: 1rem;
  transition-duration: 0.3s;
  transform-origin: center;
}
.slider .slide-elem.goto-archive-slide > a .arrow > svg {
  width: 100%;
  height: 100%;
}
.slider .slide-elem.goto-archive-slide > a .arrow > svg circle {
  fill: var(--c-accent);
}
.slider .slide-elem.goto-archive-slide > a .text {
  width: 100%;
  text-align: center;
  color: var(--c-accent);
  font-weight: bold;
  transition-duration: 0.3s;
}
.slider .slide-elem.goto-archive-slide > a:hover .arrow {
  transform: scale(0.9) translateY(-0.5rem);
}
.slider .slider-navigation {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 4rem;
}
@media screen and (max-width: 731px) {
  .slider .slider-navigation {
    display: none;
  }
}
.slider .slider-navigation .swiper-button-prev,
.slider .slider-navigation .swiper-button-next {
  position: relative;
  top: 0;
  margin-top: 0;
  width: fit-content;
  height: fit-content;
}
.slider .slider-navigation .swiper-button-prev::after,
.slider .slider-navigation .swiper-button-next::after {
  font-size: 2rem;
}
.slider .slider-navigation .swiper-button-prev {
  margin-right: 3rem;
}
.slider .slider-navigation .swiper-button-prev::after {
  content: "\f060";
}
.slider .slider-navigation .swiper-button-next::after {
  content: "\f061";
}

.title-line-graph {
  height: 5px;
  width: 10rem;
  margin-bottom: 1rem;
}

.words-slider {
  border-top: 2px solid #ececec;
  border-bottom: 2px solid #ececec;
}
.words-slider .list-item {
  text-decoration: none;
  color: inherit;
  padding: 0 15px;
}
.words-slider .list-item .list-arrow {
  padding-right: 2rem;
}
.words-slider .list-item .list-arrow > i {
  transition-duration: 0.3s;
}
.words-slider .list-item:hover {
  background-color: #ececec;
}
.words-slider .list-item:hover .list-arrow > i {
  transform: translateX(1rem);
}

.list .list-item {
  border-top: 2px solid #ececec;
  padding: 0.5rem 0;
  text-decoration: none;
  color: inherit;
  transition-duration: 0.3s;
}
@media screen and (max-width: 731px) {
  .list .list-item {
    margin-bottom: 1rem;
  }
}
.list .list-item:last-child {
  border-bottom: 2px solid #ececec;
}
.list .list-item .when,
.list .list-item .where,
.list .list-item .what {
  display: flex;
  height: 2rem;
  align-items: center;
}
@media screen and (max-width: 731px) {
  .list .list-item .when,
.list .list-item .where,
.list .list-item .what {
    font-size: 0.85rem;
  }
}
.list .list-item .when .fas,
.list .list-item .where .fas,
.list .list-item .what .fas {
  margin-right: 0.5rem;
  font-size: 1.2rem;
  width: 2rem;
  text-align: center;
}
@media screen and (max-width: 731px) {
  .list .list-item .when .fas,
.list .list-item .where .fas,
.list .list-item .what .fas {
    font-size: 1rem;
    width: 1.5rem;
  }
}
.list .list-item h5 {
  max-width: 20rem;
}
@media screen and (max-width: 731px) {
  .list .list-item h5 {
    margin-top: 1rem;
  }
}
.list .list-item .list-arrow {
  padding-right: 2rem;
}
.list .list-item .list-arrow > i {
  transition-duration: 0.3s;
}
.list .list-item:hover {
  background-color: #ececec;
}
.list .list-item:hover .list-arrow > i {
  transform: translateX(1rem);
}
.list.in-single .list-item {
  border: 0px;
  padding: 0;
}
.list.in-single .list-item:hover {
  background-color: unset;
}

.team {
  padding: 0;
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.team-member {
  color: #494949;
  text-decoration: none;
  line-height: 2;
  transition-duration: 300ms;
  text-align: center;
}
.team-member > a {
  text-decoration: none;
}
.team-member img {
  transition-duration: 300ms;
  margin-bottom: 1rem;
  border-radius: 50%;
  border: 3px solid transparent;
}
.team-member .name {
  transition-duration: 300ms;
  font-weight: bold;
  text-align: center;
  color: #494949;
  text-decoration: none !important;
}
.team-member .email,
.team-member .phone {
  text-align: center;
}
.team-member .email a,
.team-member .phone a {
  color: #5498a3;
  text-decoration: none !important;
}
.team-member .linkedin {
  margin-top: 0.5rem;
  text-align: center;
}
.team-member .linkedin a {
  color: inherit;
  text-decoration: none !important;
}
.team-member:not(.no-hover):hover img {
  border: 3px solid #5498a3;
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.11);
}
.team-member:not(.no-hover):hover .name {
  transform: scale(0.9) translateY(-0.5rem);
}

.staff-ul-list li {
  font-weight: bold;
  color: #494949;
  text-decoration: none !important;
}

.hero .p-big {
  font-weight: 400;
  color: #494949;
}

.hero.new {
  background-color: var(--c-accent);
  height: 75vh;
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.hero.new > img {
  position: absolute;
  transform-origin: center;
  transform: scale(1.2) translateX(-10vw) translateY(-20vh);
  z-index: -1;
}
.hero.new .dots {
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.3;
}
.hero.new .dots svg {
  position: absolute;
  height: 100%;
  right: 0;
}
.hero.new .dots > svg * {
  fill: var(--c-accent-inv);
}
@media screen and (max-width: 768px) {
  .hero.new .dots {
    display: none;
  }
}
.hero.new h1 {
  color: var(--c-accent-inv) !important;
}
.hero.new .p-big {
  font-weight: 500;
  color: var(--c-accent-inv);
}

.home-hero {
  position: relative;
  height: 75vh;
}
@media screen and (max-width: 731px) {
  .home-hero {
    margin-top: 1rem;
  }
}
.home-hero .hero-circle {
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 731px) {
  .home-hero .hero-circle {
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    top: 8rem;
  }
}
.home-hero .hero-circle svg {
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 731px) {
  .home-hero .hero-circle svg {
    height: 100%;
    opacity: 0.7;
  }
}
.home-hero .hero-content {
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 731px) {
  .home-hero .hero-content {
    bottom: unset;
    top: 3rem;
    width: 100%;
    position: relative;
    margin-bottom: 8rem;
  }
}
.home-hero .hero-content h1 {
  font-weight: 200;
  margin-bottom: 5vh;
  font-size: clamp(1.3rem, 4vw, 5rem);
}
@media screen and (max-width: 731px) {
  .home-hero .hero-content h1 {
    font-size: 1.7rem;
  }
}
.home-hero .hero-content h1 b {
  font-weight: 800;
}
.home-hero .hero-content .hero-paragraph {
  font-size: 1.8rem;
  font-size: clamp(1.3rem, 2vw, 2rem);
  font-weight: 200;
  line-height: 1.5;
  width: 47vw;
  max-width: 50rem;
}
@media screen and (max-width: 731px) {
  .home-hero .hero-content .hero-paragraph {
    max-width: unset;
    width: 100%;
    font-size: 1.2rem;
    font-weight: normal;
  }
}

.nohero-title {
  margin-top: 6rem;
}
@media screen and (max-width: 731px) {
  .nohero-title {
    margin-top: 3rem;
  }
}

.circle-quarter {
  position: absolute;
  top: 0;
  right: 0;
  height: 80vh;
  z-index: -1;
}
.circle-quarter .overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.8) 5vh, rgba(255, 255, 255, 0) 50%);
}
.circle-quarter svg {
  width: auto;
  height: 100%;
  transform: translateX(30vw);
}
.circle-quarter.new {
  right: unset;
  width: 100%;
  overflow: hidden;
}
.circle-quarter.new svg {
  transform: translateX(50vw);
}
.circle-quarter.grey svg * {
  fill: #ececec;
}
.circle-quarter.blueish svg * {
  fill: #5498a3;
}

.filebox {
  padding: 1rem 2rem;
  top: 1rem;
  margin-bottom: 1rem;
  background: #f9f9f9;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  border: 2px solid #ececec;
}
.filebox a {
  display: block;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  transition-duration: 150ms;
}
.filebox a:hover {
  color: #5498a3;
}
.filebox a img {
  margin-right: 1rem;
  height: 1.4em;
}

#content {
  padding-top: 0;
  padding-bottom: 0;
  position: static;
  z-index: 1;
}

footer#footer {
  background-color: #494949;
  padding: 4rem 0;
  color: #fff;
}
footer#footer .footer-logo {
  height: 2rem;
  margin-bottom: 1.5rem;
}
footer#footer small {
  font-size: 0.8rem;
}
footer#footer a {
  color: #fff;
  text-decoration: none;
}
footer#footer .footer-socials {
  font-size: 1.5rem;
  line-height: 1;
}
footer#footer .footer-socials a {
  line-height: 0.7;
  display: inline-block;
}
@media screen and (max-width: 731px) {
  footer#footer .footer-menu {
    margin-top: 1rem;
  }
}
footer#footer .footer-menu ul {
  padding: 0;
  list-style-type: none;
}
footer#footer .footer-menu ul li a {
  font-size: 0.75rem;
}
footer#footer .footer-newsletter-input {
  display: flex;
}
@media screen and (max-width: 731px) {
  footer#footer .footer-newsletter-input .email {
    width: 70%;
  }
}
footer#footer input[type=email] {
  border-radius: 0;
  border: 0px;
  padding: 0.3rem;
}
footer#footer input[type=submit] {
  background-color: #5498a3;
  color: #fff;
  border-radius: 0;
  border: 0px;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0 0.7rem;
}
footer#footer #footer-newsletter-privacy {
  font-size: 0.75rem;
  margin-top: 0.75rem;
}
footer#footer #footer-newsletter-privacy .checkbox {
  display: flex;
  align-items: center;
}
footer#footer #footer-newsletter-privacy .checkbox #footer-privacy {
  margin-right: 0.5rem;
}
footer#footer .footer-ctas a {
  color: #5498a3;
  text-transform: uppercase;
  font-size: 0.75rem;
}

div,
section {
  /* #region  COLORS */
  /* #endregion */
}
div[data-bg=grey],
section[data-bg=grey] {
  background-color: #ececec;
  color: inherit;
}
div[data-color=blueish],
section[data-color=blueish] {
  --c-accent: #5498a3;
}
div[data-color=blueish] .accent-color,
section[data-color=blueish] .accent-color {
  color: #5498a3;
}
div[data-color=blueish] h3,
section[data-color=blueish] h3 {
  color: #5498a3;
}
div[data-color=blueish] .swiper-button-prev,
div[data-color=blueish] .swiper-button-next,
section[data-color=blueish] .swiper-button-prev,
section[data-color=blueish] .swiper-button-next {
  color: #5498a3;
}
div[data-color=blueish] .slider .title-line,
section[data-color=blueish] .slider .title-line {
  background-color: #5498a3;
}
div[data-color=blueish] .slider .read-more,
section[data-color=blueish] .slider .read-more {
  color: #5498a3;
}
div[data-bg=blueish],
section[data-bg=blueish] {
  background-color: #5498a3;
  color: #fff;
}
div[data-color=red],
section[data-color=red] {
  --c-accent: #e73b2b;
}
div[data-color=red] .accent-color,
section[data-color=red] .accent-color {
  color: #e73b2b;
}
div[data-color=red] h3,
section[data-color=red] h3 {
  color: #e73b2b;
}
div[data-color=red] .swiper-button-prev,
div[data-color=red] .swiper-button-next,
section[data-color=red] .swiper-button-prev,
section[data-color=red] .swiper-button-next {
  color: #e73b2b;
}
div[data-color=red] .slider .title-line,
section[data-color=red] .slider .title-line {
  background-color: #e73b2b;
}
div[data-color=red] .slider .read-more,
section[data-color=red] .slider .read-more {
  color: #e73b2b;
}
div[data-color=green],
section[data-color=green] {
  --c-accent: #27a536;
}
div[data-color=green] .accent-color,
section[data-color=green] .accent-color {
  color: #27a536;
}
div[data-color=green] h3,
section[data-color=green] h3 {
  color: #27a536;
}
div[data-color=green] .swiper-button-prev,
div[data-color=green] .swiper-button-next,
section[data-color=green] .swiper-button-prev,
section[data-color=green] .swiper-button-next {
  color: #27a536;
}
div[data-color=green] .slider .title-line,
section[data-color=green] .slider .title-line {
  background-color: #27a536;
}
div[data-color=green] .slider .read-more,
section[data-color=green] .slider .read-more {
  color: #27a536;
}
div[data-color=yellow],
section[data-color=yellow] {
  --c-accent: #fab600;
}
div[data-color=yellow] .accent-color,
section[data-color=yellow] .accent-color {
  color: #fab600;
}
div[data-color=yellow] h3,
section[data-color=yellow] h3 {
  color: #fab600;
}
div[data-color=yellow] .swiper-button-prev,
div[data-color=yellow] .swiper-button-next,
section[data-color=yellow] .swiper-button-prev,
section[data-color=yellow] .swiper-button-next {
  color: #fab600;
}
div[data-color=yellow] .slider .title-line,
section[data-color=yellow] .slider .title-line {
  background-color: #fab600;
}
div[data-color=yellow] .slider .read-more,
section[data-color=yellow] .slider .read-more {
  color: #fab600;
}

section:not(.m-section) {
  padding: var(--section-padding) 0;
}
section .bg-dots-blueish {
  background-image: url("../../assets/img/half-circle-blueish.svg");
  background-size: cover;
  background-position: center;
}
section .bg-dots-grey {
  background-image: url("../../assets/img/half-circle-grey.svg");
  background-size: cover;
  background-position: center;
}
section .m-section {
  margin: var(--section-padding) 0;
}
section h3 {
  text-transform: uppercase;
}
section.sectors h3.h2 {
  color: #fff;
}
section.sectors .box {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 3px;
  color: #000;
  padding: 2rem 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
section.sectors .box > p {
  margin: 2rem 0;
}
section.sectors .box ul {
  padding-left: 1rem;
  margin-bottom: 0;
}
section.sectors .box ul li {
  margin: 0.5rem 0;
}
section.sectors .box ul li:last-child {
  margin-bottom: 0;
}

section.partners {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
section.partners.socio {
  background-image: url("../../assets/img/abstract-bg-partners.svg");
}
section.partners.economia-sociale {
  background-image: url("../../assets/img/abstractBG.jpg");
}
section.partners h3 {
  color: #fff;
}
section.partners img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}

section.our-network .swiper-slide {
  width: 10rem;
  height: 10rem;
}
section.our-network .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content-sections .section:not(:last-child) {
  margin-bottom: 8rem;
}
@media screen and (max-width: 731px) {
  .content-sections .section:not(:last-child) {
    margin-bottom: 4rem;
  }
}
.content-sections h2 {
  margin-bottom: 4rem;
}
.content-sections .box {
  margin-top: 4rem;
}
@media screen and (max-width: 731px) {
  .content-sections .box {
    margin-top: 1rem;
  }
}
.content-sections a {
  color: var(--c-accent);
}
@media screen and (max-width: 731px) {
  .content-sections img.img-fluid {
    margin: auto;
    display: block;
    margin-bottom: 1rem;
  }
}

.container-offset-helper {
  height: 0px;
  overflow-y: hidden;
}

.section-members .member-logo {
  height: 6rem;
  filter: grayscale(100%);
  transition-duration: 0.5s;
}
@media screen and (max-width: 731px) {
  .section-members .member-logo {
    width: 100%;
    object-fit: contain;
  }
}
.section-members .member-logo:hover {
  filter: grayscale(0%);
}

.section-paragraphs {
  margin-top: 4rem;
}
@media screen and (max-width: 731px) {
  .section-paragraphs {
    margin-top: 2rem;
  }
}
.section-paragraphs .paragraph .paragraph-content {
  padding-right: 4rem;
}
@media screen and (max-width: 731px) {
  .section-paragraphs .paragraph .paragraph-content {
    padding-right: 0;
  }
}

#glossary-accordion img {
  width: 0.9rem;
  padding-top: 2px;
}
#glossary-accordion .accordion-item .accordion-header .accordion-button {
  outline: none !important;
  box-shadow: none !important;
}
#glossary-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: rgba(84, 152, 163, 0.2);
  color: #5498a3;
}
#glossary-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik0xLjYgNC42Yy4yLS4yLjUtLjIuNyAwTDggMTAuM2w1LjYtNS42Yy4yLS4yLjUtLjIuNyAwcy4yLjUgMCAuN2wtNiA2Yy0uMi4yLS41LjItLjcgMGwtNi02Yy0uMS0uMi0uMS0uNiAwLS44eiIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6IzU0OThhMyIvPjwvc3ZnPg==);
}
#glossary-accordion .accordion-item .accordion-body .glossary-video {
  width: 100%;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 731px) {
  #glossary-accordion .accordion-item .accordion-body .glossary-video {
    margin-top: 2rem;
  }
}
#glossary-accordion .accordion-item .accordion-body .glossary-video iframe {
  width: 100%;
  position: sticky;
  top: 15px;
}

.newsletter-form p {
  margin-bottom: 1rem;
}
.newsletter-form .tc {
  font-size: 0.8rem;
  font-weight: normal !important;
}

body.home h1 {
  color: #5498a3;
}
body.home .news .hp-slide a {
  background: #ececec;
  padding: 0.75em 0.6rem 1rem 0.6rem;
  display: block;
  width: 100%;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
body.home .news .hp-slide a.read-more {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

body.error404 .wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
body.error404 .wrapper h1 {
  font-size: 9rem;
  margin-bottom: 2rem;
}
body.error404 .wrapper h1 img {
  display: inline-block;
  height: 1em;
  margin: 0 0.1em;
  transform: translateY(-0.1em);
}

body.body-mt .body-spacer {
  position: relative;
  height: 10vh;
}
body.body-mt .content-wrapper .hero {
  min-height: 55vh;
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  position: relative;
}
body.body-mt .content-wrapper .hero a {
  text-decoration: none;
  font-weight: bold;
}
body.body-mt .content-wrapper .hero > img {
  position: absolute;
  transform-origin: center;
  transform: scale(1.2) translateX(-10vw) translateY(-20vh);
}
body.body-mt .content-wrapper .hero.new a {
  color: #ececec;
}

body.page-template-template-pubblicazioni .publications-lists section:nth-child(even),
body.page-template-template-news .publications-lists section:nth-child(even) {
  background-color: #ececec;
}
body.page-template-template-pubblicazioni .publications-lists section .publication-archive-link,
body.page-template-template-news .publications-lists section .publication-archive-link {
  color: #5498a3;
  text-decoration: none;
  margin-top: 1rem;
  display: block;
}
body.page-template-template-pubblicazioni .slider,
body.page-template-template-news .slider {
  margin-top: 1rem;
}
body.page-template-template-pubblicazioni .swiper,
body.page-template-template-news .swiper {
  opacity: 0;
  transition-duration: 0.3s;
}
body.page-template-template-pubblicazioni .swiper.swiper-initialized,
body.page-template-template-news .swiper.swiper-initialized {
  opacity: 1;
}
body.page-template-template-pubblicazioni .navigation,
body.page-template-template-news .navigation {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #5498a3;
}
body.page-template-template-pubblicazioni .navigation > div,
body.page-template-template-news .navigation > div {
  margin: 0 5px;
}
body.page-template-template-pubblicazioni .publication-slide,
body.page-template-template-news .publication-slide {
  padding-right: 4rem;
}
@media screen and (max-width: 731px) {
  body.page-template-template-pubblicazioni .publication-slide,
body.page-template-template-news .publication-slide {
    padding-right: 30px;
  }
}
body.page-template-template-pubblicazioni .publication-slide:not(.swiper-slide),
body.page-template-template-news .publication-slide:not(.swiper-slide) {
  margin-bottom: 10vh;
}
body.page-template-template-pubblicazioni .publication-slide > a,
body.page-template-template-news .publication-slide > a {
  text-decoration: none;
  color: inherit;
}
body.page-template-template-pubblicazioni .publication-slide .image,
body.page-template-template-news .publication-slide .image {
  height: 20rem;
  margin-bottom: 1rem;
  border: 5px solid var(--c-accent);
  object-fit: contain;
}
@media screen and (max-width: 731px) {
  body.page-template-template-pubblicazioni .publication-slide .image,
body.page-template-template-news .publication-slide .image {
    height: 14rem;
  }
}
@media screen and (max-width: 731px) {
  body.page-template-template-pubblicazioni .publication-slide .image,
body.page-template-template-news .publication-slide .image {
    width: auto !important;
  }
}
body.page-template-template-pubblicazioni .publication-slide .title,
body.page-template-template-news .publication-slide .title {
  font-size: 1.2rem;
  font-weight: bold;
  min-height: 4em;
}
body.page-template-template-pubblicazioni .publication-slide .separator,
body.page-template-template-news .publication-slide .separator {
  width: 20rem;
  height: 5px;
  position: relative;
  background-color: #5498a3;
  margin: 1rem 0;
}
@media screen and (max-width: 731px) {
  body.page-template-template-pubblicazioni .publication-slide .separator,
body.page-template-template-news .publication-slide .separator {
    width: 100%;
  }
}
body.page-template-template-pubblicazioni .jeod-section,
body.page-template-template-news .jeod-section {
  padding: 4rem 0;
}
body.page-template-template-pubblicazioni .jeod-section .jeod-logo,
body.page-template-template-news .jeod-section .jeod-logo {
  height: 8rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 731px) {
  body.page-template-template-pubblicazioni .jeod-section .jeod-logo,
body.page-template-template-news .jeod-section .jeod-logo {
    width: 100%;
    height: auto;
  }
}
body.page-template-template-pubblicazioni .jeod-section .read-more,
body.page-template-template-news .jeod-section .read-more {
  color: #5498a3;
  text-transform: uppercase;
}

body.single-publications .publication-img {
  border: 7px solid #5498a3;
}
body.single-publications .publication-download img {
  margin-right: 0.5rem;
  height: 1.5rem;
}
body.single-publications .publication-download a {
  background-color: #ececec;
  border-color: #939393;
  text-decoration: none;
  color: #494949;
  transition-duration: 150ms;
}
body.single-publications .publication-download a:hover {
  background-color: lightgray;
  border-color: #7a7a7a;
}
body.single-publications .publication-download a span {
  transform: translateY(2px);
  display: inline-block;
}

body.page-template-template-chi-siamo h3 {
  color: #5498a3;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
body.page-template-template-chi-siamo h3:first-of-type {
  margin-top: 0;
}
body.single-peoples h1 {
  margin-bottom: 0rem;
}
body.single-peoples .role {
  font-size: 1.7rem;
  color: #939393;
  margin-bottom: 2rem;
  font-weight: 500;
}
@media screen and (max-width: 731px) {
  body.single-peoples .role {
    font-size: 1.3rem;
  }
}

.wpforms-form input:not([type=checkbox]) {
  width: 100% !important;
  max-width: unset !important;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-form div.wpforms-container-full .wpforms-form .wpforms-title {
  text-align: center;
}
.page-form div.wpforms-container-full .wpforms-form input.wpforms-field-medium,
.page-form div.wpforms-container-full .wpforms-form select.wpforms-field-medium,
.page-form div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-medium {
  max-width: unset;
}
.page-form div.wpforms-container-full .wpforms-form input[type=submit],
.page-form div.wpforms-container-full .wpforms-form button[type=submit],
.page-form div.wpforms-container-full .wpforms-form .wpforms-page-button {
  font-size: 1rem;
  border-radius: 0.25rem;
  background-color: var(--c-accent);
  border-color: var(--c-accent-d);
  color: var(--c-accent-inv);
}
.page-form div.wpforms-container-full .wpforms-form input[type=submit]:hover,
.page-form div.wpforms-container-full .wpforms-form button[type=submit]:hover,
.page-form div.wpforms-container-full .wpforms-form .wpforms-page-button:hover {
  border-color: var(--c-accent-d);
  background-color: var(--c-accent-d);
}

@media screen and (max-width: 731px) {
  .entry-title-pages h1 {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 731px) {
  .single-peoples .team-member {
    width: 60%;
    margin: auto;
  }
}

@media screen and (max-width: 731px) {
  .swiper-slide {
    width: 90%;
  }

  .w-75-mobile {
    width: 75% !important;
  }

  ul.dropdown-menu.show {
    border: none !important;
  }

  a.nav-link {
    outline: none !important;
  }
}
.form-pubblicazioni select,
.form-pubblicazioni button,
.form-pubblicazioni input {
  font-size: 0.9rem;
}

@media screen and (min-width: 1200px) {
  .w-xl-75 {
    width: 75%;
  }
}
@supports (-moz-appearance: none) {
  nav.navbar {
    background-color: white;
  }
}
.virgola:not(:last-child):after {
  content: ",";
}

iframe {
  max-width: 100%;
}

.see-all {
  font-size: 0.75rem;
}

.searchwp-modal-form-trigger-el {
  color: #494949;
}

.tease a {
  color: #494949;
  font-weight: 700;
  text-decoration: none;
}

.tease p a {
  display: block;
  margin-top: 1rem;
}

.searchwp-modal-form-default .searchwp-modal-form__content #searchsubmit {
  font-size: 0.75rem;
}
@media screen and (max-width: 768px) {
  .searchwp-modal-form-default .searchwp-modal-form__content {
    max-width: 95%;
    margin: auto;
    font-size: 0.9rem;
    padding: 4em 1em !important;
  }
  .searchwp-modal-form-default .searchwp-modal-form__content #searchsubmit {
    font-size: 0.85rem;
  }
}

.searchwp-modal-form__container:focus-visible {
  outline: none;
}

.asterisk {
  color: #e73b2b;
}

.mc-list {
  list-style: none;
}

.mc_fieldset input,
.mc-list input {
  margin-right: 0.5rem;
}

@media screen and (max-width: 768px) {
  .input-group,
.input-group button {
    font-size: 0.7rem;
  }
}
nav#top-bar {
  position: relative;
  font-size: 0.8rem;
  background-color: #494949;
  padding: 0.5rem 2vw;
}
@media screen and (max-width: 812px) {
  nav#top-bar {
    display: none;
  }
}
nav#top-bar a {
  color: #ececec;
  text-decoration: none;
}
nav#top-bar a:hover {
  color: white;
}
nav#top-bar .social-icons {
  font-size: 1.2rem;
}
nav#top-bar .topbar-search {
  border: 1px solid #ececec;
  padding-left: 0.75rem;
}
nav#top-bar #search-btn {
  padding: 0;
  width: 2rem;
}
nav#top-bar .lang-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
nav#top-bar .lang-switch .dropdown .dropdown-toggle {
  text-transform: uppercase;
  color: #494949;
  border: 1px solid #cdcdcd;
  background-color: #efefef;
  border-radius: 6px;
  padding: 6px;
  text-align: center;
  width: 2rem;
}
nav#top-bar .lang-switch .dropdown .dropdown-toggle.show {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
nav#top-bar .lang-switch .dropdown .dropdown-toggle::after {
  content: none;
}
nav#top-bar .lang-switch .dropdown .dropdown-menu {
  min-width: unset;
  text-transform: uppercase;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  margin-top: 0;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 2rem;
}
nav#top-bar .lang-switch .dropdown .dropdown-menu > li > a.dropdown-item {
  text-align: center;
  padding: 0;
  text-transform: uppercase;
  padding: 0;
  color: #494949;
}
nav#top-bar .lang-switch .dropdown .dropdown-menu > li > a.dropdown-item:focus {
  background-color: #ccc;
}

.page-template-template-grafici iframe {
  min-height: 500px !important;
}

.chart-col .caption {
  font-size: 0.85rem;
  font-style: italic;
  opacity: 0.8;
}

.back-top-container {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
@media screen and (max-width: 731px) {
  .back-top-container {
    bottom: 4rem;
  }
}

.back-to-top-text {
  opacity: 0;
  visibility: hidden;
  color: #ececec;
}

.back-to-top {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  background-color: #5498a3;
  border: 2px solid #ececec;
  color: #ececec;
  transition: 0.2s;
}
.back-to-top:hover {
  border: 2px solid #5498a3;
  color: #ececec;
}
.back-to-top .sub-chevron {
  transform: rotate(180deg);
}
.back-to-top .sub-chevron .icon {
  font-size: 0.8rem;
}

.back-to-top--is-visible {
  visibility: visible;
  opacity: 1;
}

.toc-title {
  margin-top: 4rem;
}
@media screen and (min-width: 1200px) {
  .toc-title {
    margin-top: 8rem;
  }
}

ul#toc {
  list-style: none;
  padding-left: 0;
}

#dynamic-toc #toc li a h5 {
  color: inherit;
}

.page-template-template-grafici .nohero-title .paragraph-content {
  font-size: 1.2rem;
  font-weight: 500;
}