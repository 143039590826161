@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;700&display=swap');

$c_grey: #ececec;
$c_grey_d: #939393;
$c_blue: #0071b9;
$c_blueish: #5498a3;
$c_red: #e73b2b;
$c_green: #27a536;
$c_yellow: #fab600;
$c_black: #494949;

$colors: (
	'grey': $c_grey,
	'blueish': $c_blueish,
	'red': $c_red,
	'green': $c_green,
	'yellow': $c_yellow,
	'black': $c_black,
);

/* #region  ACCENTS */
:root {
	--c-accent: #{$c_blueish};
	--c-accent-d: #{darken($c_blueish, 10)};
	--c-accent-inv: #fff;
}

[data-section='ricerca'] {
	--c-accent: #{$c_green};
	--c-accent-d: #{darken($c_green, 10)};
	--c-accent-inv: #fff;
}

[data-section='consulenza'] {
	--c-accent: #{$c_blue};
	--c-accent-d: #{darken($c_blue, 10)};
	--c-accent-inv: #fff;
}

[data-section='formazione'] {
	--c-accent: #{$c_yellow};
	--c-accent-d: #{darken($c_yellow, 10)};
	--c-accent-inv: #fff;
}

[data-section='news'] {
	--c-accent: #{$c_red};
	--c-accent-d: #{darken($c_red, 10)};
	--c-accent-inv: #fff;
}

@import 'mixins';

h1 {
	color: var(--c-accent) !important;
}

a {
	color: var(--c-accent);
}

.text-blueish {
	color: $c_blueish !important;
}

.rounded-3 {
	border-radius: .5rem !important;
}

.accent-color {
	color: var(--c-accent) !important;
}

.accent-bg {
	background-color: var(--c-accent) !important;
}

.circle-quarter {

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		top: -6rem;
	}

	svg {
		* {
			fill: var(--c-accent);
		}
	}
}

/* #endregion */

@each $name,
$color in $colors {
	.color-#{$name} {
		color: $color;
	}

	.bg-#{$name} {
		background-color: $color;
	}

	a.color-#{$name}:hover {
		color: darken($color, 10);
	}
}

// @font-face {
// 	font-family: 'Avenir';
// 	src: url('../fonts/Avenir Roman.otf');
// }

.extend-fa {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

:root {
	--size-h1: 3rem;
	--size-h2: 2rem;
	--size-h3: 2rem;
	--size-h4: 1.5rem;
	--size-h5: 1.3rem;
	--section-padding: 7rem;

	--bs-font-sans-serif: 'Red Hat Display', sans-serif;

	@media screen and (max-width: 1600px) {
		// --size-h5: 1rem;
	}

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		--size-h1: 1.7rem;
		--size-h2: 1.5rem;
		--size-h3: 1.5rem;
		--size-h4: 1.3rem;
		--size-h5: 1rem;
		--section-padding: 3rem;
	}
}

html {
	font-size: 1.2rem;
	font-family: 'Red Hat Display', sans-serif;
}

body {
	line-height: 150%;
	// rimosso dopo aver aggiunto la topbar e aver messo la navbar a sticky anzichè absolute
	// &:not(.home) {
	// 	padding-top: 4rem;
	// }
}

h1,
.h1 {
	font-size: var(--size-h1);
	font-weight: bold;
	color: $c_blueish;
	text-transform: uppercase;

	&.size-h2 {
		font-size: var(--size-h2);
	}
}

h2,
.h2 {
	font-size: var(--size-h2);
	font-weight: bold;
	color: $c_black;
}

h3,
.h3 {
	font-size: var(--size-h3);
	font-weight: 300;
	color: $c_black;
}

h4,
.h4 {
	font-size: var(--size-h4);
	font-weight: 300;
	color: $c_black;
	margin-bottom: 0;

	&:not(:first-child) {
		margin-top: 2rem;
	}
}

h5,
.h5 {
	font-size: var(--size-h5);
	color: $c_black;
}

a {
	color: $c_blueish;
}

.bigger {
	font-size: 1.5em;
	line-height: 1.5;

	@media screen and (max-width: 1200px) {
		font-size: 1.3em;
	}

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		font-size: 1rem;
	}
}

.p-big {
	font-size: 1.2rem;
	color: $c_grey_d;

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		font-size: 1.1rem;
	}

	p {
		margin-bottom: 0;
	}
}

.container {
	@media screen and (max-width: 1200px) and (min-width: 731px) {
		max-width: calc(96vw);
	}

	@media screen and (min-width: 1440px) {
		max-width: 1400px;
	}

	@media screen and (min-width: 1680px) {
		max-width: 1600px;
	}
}

.btn {
	box-shadow: none;

	&:focus {
		box-shadow: none;
	}

	&.btn-primary {
		background-color: $c_blueish;
		color: #fff;
		border-color: #fff;
	}

	&.btn-action {
		&::before {
			@extend .extend-fa;
			content: '\f061';
			padding-right: 1rem;
			transition-duration: 0.3s;
		}

		&:hover::before {
			transform: translateX(0.5rem);
		}
	}

	&.btn-shadow {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}
}

nav.navbar {
	position: sticky;
	left: 0;
	// top: 2rem;
	// position: fixed;
	background-color: rgba(255, 255, 255, 0.55);
	backdrop-filter: blur(10px);
	// border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	top: 0;
	width: 100%;
	padding: 0px 2vw;
	z-index: 100;
	// box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 6px 6px rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.05);

	//FIX NON FUNZIONANTE PER IL BLUR DI MOZILLA
	// &::after {
	//   content: "";
	// height: 100%;
	// width: 100%;
	// position: absolute;
	// z-index: -1;
	// background-color: hsla(0,0%,100%,.55);
	// filter: blur(10px);
	// }

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		top: 0;
		padding-right: 0;
		padding-left: 0;
		background-color: #fff;
		text-align: center;
	}

	--h: 4rem;

	@media screen and (max-width: 1200px) {
		--h: 3rem;
	}

	@media screen and (max-width: 731px) {
		--h: 2.5rem;
	}

	.navbar-brand {
		padding: 0;
		height: var(--h);
		display: flex;
		align-items: center;

		img {
			height: 60%;
		}
	}

	.navbar-toggler {
		border: 0px;
		outline: 0;
		padding: 0;

		&:focus {
			box-shadow: none;
		}

		.navbar-toggler-icon {
			// width: 1rem;
			// height: 1rem;
		}

		.icon {
			width: 25px;
			height: 20px;
			position: relative;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: 0.5s ease-in-out;
			-moz-transition: 0.5s ease-in-out;
			-o-transition: 0.5s ease-in-out;
			transition: 0.5s ease-in-out;
			cursor: pointer;

			span {
				display: block;
				position: absolute;
				height: 2px;
				width: 100%;
				background: $c_black;
				border-radius: 1px;
				opacity: 1;
				left: 0;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.25s ease-in-out;
				-moz-transition: 0.25s ease-in-out;
				-o-transition: 0.25s ease-in-out;
				transition: 0.25s ease-in-out;

				&:nth-child(1) {
					top: 0px;
				}

				&:nth-child(2) {
					top: 10px;
				}

				&:nth-child(3) {
					top: 10px;
				}

				&:nth-child(4) {
					top: 20px;
				}
			}
		}

		&:not(.collapsed) {
			span {
				&:nth-child(1) {
					top: 10px;
					width: 0%;
					left: 50%;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}

				&:nth-child(4) {
					top: 10px;
					width: 0%;
					left: 50%;
				}
			}
		}
	}

	.navbar-nav {

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			margin-bottom: 10px;
		}

		.nav-item {
			height: var(--h);
			display: flex;
			align-items: center;
			position: relative;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				justify-content: center;
				height: auto;
				display: block;
				// padding-left: 1rem;
			}

			&::after {
				content: '';
				display: block;
				width: 100%;
				height: 0px;
				background-color: $c_blueish;
				position: absolute;
				bottom: 0;
				left: 0;
				transition-duration: 0.3s;
				opacity: 0.9;
				z-index: -1;
			}

			&.accent-green::after {
				background-color: $c_green;
			}

			&.accent-yellow::after {
				background-color: $c_yellow;
			}

			&.accent-blue::after {
				background-color: $c_blue;
			}

			&.accent-red::after {
				background-color: $c_red;
			}

			@media screen and (min-width: 991px) {
				&:hover::after {
					height: 10px;
				}

				&.current-menu-item {
					&::after {
						height: 5px;
					}
				}
			}

			.nav-link {
				font-size: 0.9rem;
				padding-right: 1rem;
				padding-left: 1rem;
				font-weight: 500;
				color: $c_black;
				text-transform: uppercase;
				line-height: 1;
				text-align: center;

				@media screen and (max-width: 1400px) {
					padding-left: .33rem;
					padding-right: .33rem;
					// font-size: 0.7rem;
				}

				&:focus {

					// @MEDIA MOBILE
					@media screen and (max-width: 731px) {
						padding-left: 0;
						padding-right: 0;
						color: $c_blueish !important;
					}
				}

				&.dropdown-toggle {
					&::after {
						@media screen and (min-width: 731px) {
							content: none;
						}
					}
				}
			}
		}
	}

	.lang-switch {
		display: flex;
		// display: none;
		justify-content: center;
		align-items: center;
		position: relative;

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			// position: absolute;
			// top: 5px;
			// right: 0;
			// transform: none;
			// margin-top: 1rem;
		}

		.dropdown {
			.dropdown-toggle {
				text-transform: uppercase;
				color: $c_black;
				border: 1px solid #cdcdcd;

				background-color: #efefef;
				border-radius: 6px;
				padding: 6px;
				text-align: center;

				font-size: 0.8rem;
				width: 2rem;

				&.show {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

				&::after {
					content: none;
				}
			}

			.dropdown-menu {
				min-width: unset;
				text-transform: uppercase;
				border: 1px solid #ccc;
				border-radius: 6px;
				background: #fff;
				font-size: 0.8rem;
				margin-top: 0;
				border-top: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				width: 2rem;

				>li {
					>a.dropdown-item {
						text-align: center;
						padding: 0;
						text-transform: uppercase;
						padding: 0;
						color: $c_black;

						&:focus {
							background-color: #ccc;
						}
					}
				}
			}
		}
	}

	.navbar-colors {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		height: 5px;
		opacity: 0;
		transition-duration: 200ms;

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			display: flex;
		}

		.y,
		.b,
		.r,
		.g {
			flex: 1 0 0%;
		}

		.y {
			background-color: $c_yellow;
		}

		.b {
			background-color: $c_blue;
		}

		.r {
			background-color: $c_red;
		}

		.g {
			background-color: $c_green;
		}
	}

	.navbar-collapse {
		position: relative;

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			margin-top: 1rem;
		}

		&.show {
			border: 1px solid;

			@media screen and (max-width: 731px) {
				border: 0;
			}

			.navbar-colors {
				opacity: 1;
			}
		}
	}
}

.box {
	padding: 1rem;
	background: lighten($c_grey, 5);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.09);
	// border-radius: 3px;
	border-radius: 8px;
	border: 2px solid $c_grey;

	h3 {
		margin-bottom: 2rem;
	}

	h4 {
		padding-left: 1rem;
		margin-bottom: 1rem;
	}

	h5 {
		transition-duration: 0.3s;

		a {
			color: inherit;
			text-decoration: none !important;
		}

		.fa-arrow-right {
			margin-right: 1rem;
			transition-duration: 0.3s;
		}
	}

	&:not(.box-vertical-slider) {
		h5:hover {
			background-color: rgba(0, 0, 0, 0.1);

			a {
				text-decoration: none !important;
			}

			.fa-arrow-right {
				transform: translateX(0.5rem);
			}
		}
	}

	&.box-vertical-slider {
		padding: 2rem 1rem;
		background: lighten($c_grey, 5);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.09);
		border-radius: 3px;
		border: 2px solid $c_grey;

		.box-title {
			text-transform: uppercase;
		}

		.splide__slide {
			padding: 1rem;
			padding-right: 2rem;

			@media screen and (max-width: 731px) {
				padding-right: 0;
				padding: 0;
			}

			.fa-arrow-right {
				margin-right: 1rem;
				transition-duration: 0.3s;
			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.1);

				.fa-arrow-right {
					transform: translateX(0.5rem);
				}
			}
		}

		.splide__pagination__page {
			background: #fff;
			border: 1px solid $c_black;

			&.is-active {
				background: $c_black;
			}
		}
	}

	// &:not(.box-vertical-slider):not(.no-hover) {
	//   h5:hover {
	//     text-decoration: underline;
	//   }
	// }
}

.read-more {
	display: inline-block;
	text-decoration: none;
	width: fit-content;
	font-size: 1.3rem;
	font-weight: 600;

	&::before {
		@extend .extend-fa;
		content: '\f061';
		padding-right: 1.5rem;
		transition-duration: 0.3s;
	}

	@media screen and (max-width: 1200px) {
		font-size: 1rem;
	}

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		font-size: 1.1rem;

		&::before {
			padding-right: 1rem;
		}
	}

	&:hover {
		&::before {
			transform: translateX(0.5rem);
		}
	}

	// .hoverable:hover & {
	//   &::before {
	//     transform: translateX(0.5rem);
	//   }
	// }
}

img.backlogo {
	width: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}

.goto-archive-link {
	display: block;
	text-transform: uppercase;
	text-decoration: none;
	color: $c_blueish;
	font-size: 0.9rem;

	&:hover {
		color: darken($c_blueish, 10%);
	}
}

.goto-submission-link {
	display: block;
	text-transform: uppercase;
	text-decoration: none;
	color: $c_grey_d;
	font-size: 0.9rem;

	&:hover {
		color: darken($c_grey_d, 10%);
	}
}

.alignleft {
	float: left;
}

.slider {
	--swiper-navigation-size: 20px;

	.slide-elem {

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			padding: 0 15px;
		}

		>a {
			color: inherit;
			text-decoration: none;
		}

		.featured-image {
			width: 100%;
			padding-top: 57%;
			position: relative;
			background-color: #000;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				opacity: 1;
				transition-duration: 0.3s;
			}
		}

		h5 {
			margin-top: 0.5rem;
			margin-bottom: 1rem;
			font-size: var(--size-h5);
			font-weight: bold;
		}

		.title-line {
			height: 5px;
			width: 10rem;
			margin-bottom: 1rem;
		}

		.excerpt {
			@media screen and (max-width: 1400px) {
				// font-size: 0.9rem;
			}
		}

		.read-more {
			// margin-top: 1rem;

			&::before {
				transition-duration: 0.3s;
			}
		}

		&:hover {
			.featured-image {
				img {
					opacity: 0.75;
				}
			}

			.read-more {
				&::before {
					transform: translateX(0.5rem);
				}
			}
		}

		&.goto-archive {
			.featured-image {
				background-color: $c_grey;
			}
		}
	}

	.slide-elem.goto-archive-slide {
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;

		>a {
			display: block;
			width: 10rem;

			.arrow {
				width: 7rem;
				height: 7rem;
				position: relative;
				border-radius: 50%;
				background-color: #fff;
				margin: auto;
				margin-bottom: 1rem;
				transition-duration: 0.3s;
				transform-origin: center;

				>svg {
					width: 100%;
					height: 100%;

					circle {
						fill: var(--c-accent);
					}
				}
			}

			.text {
				width: 100%;
				text-align: center;
				color: var(--c-accent);
				font-weight: bold;
				transition-duration: 0.3s;
			}

			&:hover {
				.arrow {
					transform: scale(0.9) translateY(-0.5rem);
				}

				.text {
					// transform: translateY(-0.5rem);
					// opacity: 0;
				}
			}
		}
	}

	.slider-navigation {
		position: relative;
		display: flex;
		justify-content: flex-end;
		height: 4rem;

		// align-items: center;
		@media screen and (max-width: 731px) {
			display: none;
		}

		.swiper-button-prev,
		.swiper-button-next {
			position: relative;
			top: 0;
			margin-top: 0;
			width: fit-content;
			height: fit-content;

			&::after {
				@extend .extend-fa;
				font-size: 2rem;
			}
		}

		.swiper-button-prev {
			margin-right: 3rem;

			&::after {
				content: '\f060';
			}
		}

		.swiper-button-next {
			&::after {
				content: '\f061';
			}
		}
	}
}

.title-line-graph {
	height: 5px;
	width: 10rem;
	margin-bottom: 1rem;
}

.words-slider {
	border-top: 2px solid $c_grey;
	border-bottom: 2px solid $c_grey;

	.list-item {
		text-decoration: none;
		color: inherit;
		padding: 0 15px;

		.list-arrow {
			padding-right: 2rem;

			>i {
				transition-duration: 0.3s;
			}
		}

		&:hover {
			background-color: $c_grey;

			.list-arrow {
				>i {
					transform: translateX(1rem);
				}
			}
		}
	}
}

.list {
	.list-item {
		border-top: 2px solid $c_grey;
		padding: 0.5rem 0;
		text-decoration: none;
		color: inherit;
		transition-duration: 0.3s;

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			margin-bottom: 1rem;
		}

		&:last-child {
			border-bottom: 2px solid $c_grey;
		}

		.when,
		.where,
		.what {
			display: flex;
			height: 2rem;
			align-items: center;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				font-size: 0.85rem;
			}

			.fas {
				margin-right: 0.5rem;
				font-size: 1.2rem;
				width: 2rem;
				text-align: center;

				// @MEDIA MOBILE
				@media screen and (max-width: 731px) {
					font-size: 1rem;
					width: 1.5rem;
				}
			}
		}

		h5 {
			max-width: 20rem;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				margin-top: 1rem;
			}
		}

		.list-arrow {
			padding-right: 2rem;

			>i {
				transition-duration: 0.3s;
			}
		}

		&:hover {
			background-color: $c_grey;

			.list-arrow {
				>i {
					transform: translateX(1rem);
				}
			}
		}
	}

	&.in-single {
		.list-item {
			border: 0px;
			padding: 0;

			&:hover {
				background-color: unset;
			}
		}
	}
}

.team {
	padding: 0;
	margin-bottom: 5rem;
	margin-top: 2rem;
}

.team-member {
	color: $c_black;
	text-decoration: none;
	line-height: 2;
	transition-duration: 300ms;
	text-align: center;

	>a {
		text-decoration: none;
	}

	img {
		transition-duration: 300ms;
		margin-bottom: 1rem;
		border-radius: 50%;
		border: 3px solid transparent;
	}

	.name {
		transition-duration: 300ms;
		font-weight: bold;
		text-align: center;
		color: $c_black;
		text-decoration: none !important;
	}

	.email,
	.phone {
		text-align: center;

		a {
			color: $c_blueish;
			text-decoration: none !important;
		}
	}

	.linkedin {
		margin-top: 0.5rem;
		text-align: center;

		a {
			color: inherit;
			text-decoration: none !important;
		}
	}

	&:not(.no-hover):hover {
		img {
			// opacity: 0.8;
			border: 3px solid $c_blueish;
			transform: scale(1.05) translateY(-0.5rem);
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08),
				0 3px 6px rgba(0, 0, 0, 0.11);
		}

		.name {
			transform: scale(0.9) translateY(-0.5rem);
		}
	}
}

.staff-ul-list {
	li {
		font-weight: bold;
		color: $c_black;
		text-decoration: none !important;
	}
}

.hero {
	.p-big {
		font-weight: 400;
		color: $c_black;
	}
}

.hero.new {
	background-color: var(--c-accent);
	// background-color: darken($color: $c_green, $amount: 5);
	height: 75vh;
	// border: 1px solid;
	display: flex;
	width: 100%;
	align-items: center;
	overflow: hidden;
	position: relative;

	>img {
		position: absolute;
		transform-origin: center;
		transform: scale(1.2) translateX(-10vw) translateY(-20vh);
		z-index: -1;
		// transform: scale(1.2) translateX(-15vw) translateY(-10vh);
	}

	.dots {
		position: absolute;
		top: 0;
		left: 0%;
		height: 100%;
		width: 100%;
		overflow: hidden;
		opacity: 0.3;

		svg {
			position: absolute;
			height: 100%;
			right: 0;
		}

		>svg {
			* {
				fill: var(--c-accent-inv);
			}
		}

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	h1 {
		color: var(--c-accent-inv) !important;
	}

	.p-big {
		font-weight: 500;
		color: var(--c-accent-inv);
	}
}

.home-hero {
	position: relative;
	height: 75vh;

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		margin-top: 1rem;
	}

	.hero-circle {
		height: 100%;
		overflow: hidden;

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			position: absolute;
			width: 100%;
			overflow: hidden;
			z-index: -1;
			top: 8rem;
		}

		svg {
			width: 100%;
			object-fit: cover;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				// height: 100vh;
				// opacity: 0.2;
				// width: 110%;
				height: 100%;
				opacity: 0.7;
			}

			.st0,
			.st1,
			.st2,
			.st3 {
				// fill: $c_grey;
			}
		}
	}

	.hero-content {
		position: absolute;
		// border: 1px solid red;
		// bottom: 48%;
		top: 20%;
		left: 0;
		// width: 82%;
		width: 100%;

		@media screen and (max-width: 1440px) {
			// bottom: 47%;
		}

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			bottom: unset;
			top: 3rem;
			width: 100%;
			position: relative;
			margin-bottom: 8rem;
		}

		h1 {
			font-weight: 200;
			margin-bottom: 5vh;
			// font-size: 5vw;
			font-size: clamp(1.3rem, 4vw, 5rem);

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				font-size: 1.7rem;
			}

			b {
				font-weight: 800;
			}
		}

		.hero-paragraph {
			// font-size: calc(max(1rem, 2vw));
			font-size: 1.8rem;
			font-size: clamp(1.3rem, 2vw, 2rem);

			font-weight: 200;
			line-height: 1.5;
			width: 47vw;
			max-width: 50rem;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				max-width: unset;
				width: 100%;
				font-size: 1.2rem;
				font-weight: normal;
			}
		}
	}
}

.nohero-title {
	margin-top: 6rem;

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		margin-top: 3rem;
	}
}

.circle-quarter {
	position: absolute;
	top: 0;
	right: 0;
	height: 80vh;
	z-index: -1;

	// top: 0;
	// right: 0;
	// height: 75vh;
	// overflow: hidden;

	.overlay {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to top,
				rgba(255, 255, 255, 0.8) 5vh,
				rgba(255, 255, 255, 0) 50%);
	}

	svg {
		// width: 100%;
		// height: auto;
		width: auto;
		height: 100%;
		transform: translateX(30vw);
	}

	&.new {
		right: unset;
		// border: 1px solid red;
		width: 100%;
		overflow: hidden;

		svg {
			transform: translateX(50vw);
			// border: 1px solid green;
		}
	}

	&.grey {
		svg {
			* {
				fill: $c_grey;
			}
		}
	}

	&.blueish {
		svg {
			* {
				fill: $c_blueish;
			}
		}
	}
}

.filebox {
	padding: 1rem 2rem;
	top: 1rem;
	margin-bottom: 1rem;
	background: lighten($c_grey, 5);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.09);
	border-radius: 3px;
	border: 2px solid $c_grey;

	a {
		display: block;
		text-decoration: none;
		color: #000;
		display: flex;
		align-items: center;
		margin: 1rem 0;
		transition-duration: 150ms;

		&:hover {
			color: $c_blueish;
		}

		img {
			margin-right: 1rem;
			height: 1.4em;
		}
	}
}

#content {
	padding-top: 0;
	padding-bottom: 0;
	position: static;
	z-index: 1;
}

.article-body {
	// background: linear-gradient(
	//   0deg,
	//   rgba(255, 255, 255, 1) 0%,
	//   rgba(255, 255, 255, 1) 58%,
	//   rgba(255, 255, 255, 0) 100%
	// );

	// font-size: 1.15rem;
	@media screen and (max-width: 731px) {
		// font-size: 1rem;
	}
}

footer#footer {
	background-color: $c_black;
	padding: 4rem 0;
	color: #fff;

	.footer-logo {
		height: 2rem;
		margin-bottom: 1.5rem;
	}

	small {
		font-size: 0.8rem;
	}

	a {
		color: #fff;
		text-decoration: none;
	}

	.footer-socials {
		font-size: 1.5rem;
		line-height: 1;

		a {
			line-height: 0.7;
			display: inline-block;
		}
	}

	.footer-menu {

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			margin-top: 1rem;
		}

		ul {
			padding: 0;
			list-style-type: none;

			li {
				a {
					font-size: 0.75rem;
				}
			}
		}
	}

	.footer-newsletter-input {
		display: flex;

		.email {
			@media screen and (max-width: 731px) {
				width: 70%;
			}
		}
	}

	input[type='email'] {
		border-radius: 0;
		border: 0px;
		padding: 0.3rem;
	}

	input[type='submit'] {
		background-color: $c_blueish;
		color: #fff;
		border-radius: 0;
		border: 0px;
		font-size: 0.8rem;
		text-transform: uppercase;
		padding: 0 0.7rem;
	}

	#footer-newsletter-privacy {
		font-size: 0.75rem;
		margin-top: 0.75rem;

		.checkbox {
			display: flex;
			align-items: center;

			#footer-privacy {
				margin-right: 0.5rem;
			}
		}
	}

	.footer-ctas {
		a {
			color: $c_blueish;
			text-transform: uppercase;
			font-size: 0.75rem;
		}
	}
}

// @todo controllare che non dia problemi
div,
section {
	/* #region  COLORS */
	//// GREY
	@include bg-entry('grey', $c_grey);
	//// BLUEISH
	@include color-entry('blueish', $c_blueish);
	@include bg-entry('blueish', $c_blueish, #fff);
	//// RED
	@include color-entry('red', $c_red);
	//// GREEN
	@include color-entry('green', $c_green);
	//// YELLOW
	@include color-entry('yellow', $c_yellow);
	/* #endregion */
}

section {
	&:not(.m-section) {
		padding: var(--section-padding) 0;
	}

	.bg-dots-blueish {
		background-image: url('../../assets/img/half-circle-blueish.svg');
		background-size: cover;
		background-position: center;
	}

	.bg-dots-grey {
		background-image: url('../../assets/img/half-circle-grey.svg');
		background-size: cover;
		background-position: center;
	}

	.m-section {
		margin: var(--section-padding) 0;
	}

	h3 {
		text-transform: uppercase;
	}

	&.sectors {
		h3.h2 {
			color: #fff;
		}

		.box {
			background-color: #fff;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
				0 3px 6px rgba(0, 0, 0, 0.23);
			border-radius: 3px;
			color: #000;
			padding: 2rem 1.5rem;
			height: 100%;
			display: flex;
			flex-direction: column;
			// justify-content: space-between;
			// font-size: 0.9rem;

			>p {
				margin: 2rem 0;
			}

			ul {
				padding-left: 1rem;
				margin-bottom: 0;

				li {
					margin: 0.5rem 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

section.partners {
	&.socio {
		background-image: url('../../assets/img/abstract-bg-partners.svg');
	}
	&.economia-sociale {
		background-image: url('../../assets/img/abstractBG.jpg');
	}
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	h3 {
		color: #fff;
	}

	img {
		width: 4rem;
		height: 4rem;
		object-fit: contain;
	}
}

section.our-network {
	.swiper-slide {
		width: 10rem;
		height: 10rem;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.content-sections {
	.section:not(:last-child) {
		margin-bottom: 8rem;

		@media screen and (max-width: 731px) {
			margin-bottom: 4rem;
		}
	}

	.section-text {
		// font-size: 1.2rem;

		@media screen and (max-width: 1600px) {
			// font-size: 1rem;
		}
	}

	h2 {
		margin-bottom: 4rem;
	}

	.box {
		margin-top: 4rem;

		@media screen and (max-width: 731px) {
			margin-top: 1rem;
		}
	}

	a {
		color: var(--c-accent);
	}

	img.img-fluid {

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			// max-width: 7.5rem;
			margin: auto;
			display: block;
			margin-bottom: 1rem;
		}
	}
}

.container-offset-helper {
	height: 0px;
	overflow-y: hidden;
}

.section-members {
	.member-logo {
		height: 6rem;
		filter: grayscale(100%);
		transition-duration: 0.5s;

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			width: 100%;
			object-fit: contain;
		}

		&:hover {
			filter: grayscale(0%);
		}
	}
}

.section-paragraphs {
	margin-top: 4rem;

	// @MEDIA MOBILE
	@media screen and (max-width: 731px) {
		margin-top: 2rem;
	}

	.paragraph {
		.paragraph-content {
			padding-right: 4rem;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				padding-right: 0;
			}
		}
	}
}

#glossary-accordion {
	img {
		width: 0.9rem;
		padding-top: 2px;
	}

	.accordion-item {
		.accordion-header {
			.accordion-button {
				outline: none !important;
				box-shadow: none !important;

				&:not(.collapsed) {
					background-color: rgba($c_blueish, 0.2);
					color: $c_blueish;

					&::after {
						background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik0xLjYgNC42Yy4yLS4yLjUtLjIuNyAwTDggMTAuM2w1LjYtNS42Yy4yLS4yLjUtLjIuNyAwcy4yLjUgMCAuN2wtNiA2Yy0uMi4yLS41LjItLjcgMGwtNi02Yy0uMS0uMi0uMS0uNiAwLS44eiIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6IzU0OThhMyIvPjwvc3ZnPg==);
					}
				}
			}
		}

		.accordion-body {
			.glossary-video {
				width: 100%;
				height: 100%;
				position: relative;

				// @MEDIA MOBILE
				@media screen and (max-width: 731px) {
					margin-top: 2rem;
				}

				iframe {
					width: 100%;
					position: sticky;
					top: 15px;
				}
			}
		}
	}
}

.newsletter-form {
	p {
		margin-bottom: 1rem;
	}

	.tc {
		font-size: 0.8rem;
		font-weight: normal !important;
	}
}

//// HOME
body.home {
	h1 {
		color: $c_blueish;
	}

	.news {
		.hp-slide {
			a {
				background: $c_grey;
				padding: .75em .6rem 1rem .6rem;
				display: block;
				width: 100%;
				border-top-right-radius: .5rem;
				border-top-left-radius: .5rem;
				&.read-more {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					border-bottom-right-radius: .5rem;
					border-bottom-left-radius: .5rem;
				}
			}
		}
	}
}

body.error404 {
	.wrapper {
		position: relative;
		width: 100%;
		height: 100vh;
		border: 1px solid;
		display: flex;
		align-items: center;
		justify-content: center;

		h1 {
			font-size: 9rem;
			margin-bottom: 2rem;

			img {
				display: inline-block;
				height: 1em;
				margin: 0 0.1em;
				transform: translateY(-0.1em);
			}
		}
	}
}

//// PAGINA GENERICA
body.body-mt {
	.body-spacer {
		position: relative;
		height: 10vh;
	}

	.content-wrapper {
		// margin-top: 40vh;

		// // @MEDIA MOBILE
		// @media screen and (max-width: 768px) {
		//   margin-top: 6rem;
		// }

		.hero {
			// background-color: darken($color: $c_green, $amount: 5);
			min-height: 55vh;
			// border: 1px solid;
			display: flex;
			width: 100%;
			align-items: center;
			overflow: hidden;
			position: relative;

			a {
				text-decoration: none;
				font-weight: bold;
			}

			>img {
				position: absolute;
				transform-origin: center;
				transform: scale(1.2) translateX(-10vw) translateY(-20vh);
				// transform: scale(1.2) translateX(-15vw) translateY(-10vh);
			}

			&.new {
				a {
					color: $c_grey;
				}
			}
		}
	}
}

//// PUBBLICAZIONI
body.page-template-template-pubblicazioni,
body.page-template-template-news {
	.publications-lists {
		section {
			&:nth-child(even) {
				background-color: $c_grey;
			}

			.publication-archive-link {
				color: $c_blueish;
				text-decoration: none;
				margin-top: 1rem;
				display: block;
			}
		}
	}

	.slider {
		margin-top: 1rem;
	}

	.swiper {
		opacity: 0;
		transition-duration: 0.3s;

		&.swiper-initialized {
			opacity: 1;
		}
	}

	.navigation {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: flex-end;
		padding-right: 2rem;
		margin-bottom: 2rem;
		font-size: 2rem;
		color: $c_blueish;

		>div {
			margin: 0 5px;
		}
	}

	.publication-slide {
		padding-right: 4rem;

		// @MEDIA MOBILE
		@media screen and (max-width: 731px) {
			padding-right: 30px;
		}

		&:not(.swiper-slide) {
			// padding-right: 0;
			margin-bottom: 10vh;
		}

		>a {
			text-decoration: none;
			color: inherit;
		}

		.image {
			height: 20rem;
			margin-bottom: 1rem;
			// border: 5px solid $c_blueish;
			border: 5px solid var(--c-accent);
			object-fit: contain;

			@media screen and (max-width: 731px) {
				height: 14rem;
			}

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				width: auto !important;
			}
		}

		.title {
			font-size: 1.2rem;
			font-weight: bold;
			min-height: 4em;
		}

		.separator {
			width: 20rem;
			height: 5px;
			position: relative;
			background-color: $c_blueish;
			margin: 1rem 0;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				width: 100%;
			}
		}
	}

	.jeod-section {
		// background-color: $c_grey;
		padding: 4rem 0;

		.jeod-logo {
			height: 8rem;
			margin-bottom: 2rem;

			// @MEDIA MOBILE
			@media screen and (max-width: 731px) {
				width: 100%;
				height: auto;
			}
		}

		.jeod-description {
			// font-size: 1.1rem;
		}

		.read-more {
			color: $c_blueish;
			text-transform: uppercase;
			// margin-left: 2rem;
		}
	}
}

body.single-publications {
	.publication-img {
		border: 7px solid $c_blueish;
	}

	.publication-download {
		img {
			margin-right: 0.5rem;
			height: 1.5rem;
		}

		a {
			background-color: $c_grey;
			border-color: $c_grey_d;
			text-decoration: none;
			color: $c_black;
			transition-duration: 150ms;

			&:hover {
				background-color: darken($c_grey, 10);
				border-color: darken($c_grey_d, 10);
			}

			span {
				transform: translateY(2px);
				display: inline-block;
			}
		}
	}
}

//// CHI SIAMO
body.page-template-template-chi-siamo {
	h3 {
		color: $c_blueish;
		margin-top: 3rem;
		margin-bottom: 1rem;

		&:first-of-type {
			margin-top: 0;
		}
	}

	.filebox {}
}

//// PERSONA
body.single-peoples {
	h1 {
		margin-bottom: 0rem;
	}

	.role {
		font-size: 1.7rem;
		color: $c_grey_d;
		margin-bottom: 2rem;
		font-weight: 500;

		@media screen and (max-width: 731px) {
			font-size: 1.3rem;
		}
	}
}

//// WPFORMS
.wpforms-form {
	input:not([type='checkbox']) {
		width: 100% !important;
		max-width: unset !important;
	}
}

//// GALLERY
.gallery {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

//// END PAGE FORM
.page-form {
	div.wpforms-container-full .wpforms-form .wpforms-title {
		text-align: center;
	}

	div.wpforms-container-full .wpforms-form input.wpforms-field-medium,
	div.wpforms-container-full .wpforms-form select.wpforms-field-medium,
	div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-medium {
		max-width: unset;
	}

	div.wpforms-container-full .wpforms-form input[type='submit'],
	div.wpforms-container-full .wpforms-form button[type='submit'],
	div.wpforms-container-full .wpforms-form .wpforms-page-button {
		font-size: 1rem;
		border-radius: 0.25rem;
		background-color: var(--c-accent);
		border-color: var(--c-accent-d);
		color: var(--c-accent-inv);

		&:hover {
			border-color: var(--c-accent-d);
			background-color: var(--c-accent-d);
		}
	}
}

// @MEDIA MOBILE
@media screen and (max-width: 731px) {}

//CODICE LUCA
@media screen and (max-width: 731px) {
	.entry-title-pages {
		h1 {
			font-size: 1.7rem;
		}
	}
}

.single-peoples {
	.team-member {
		@media screen and (max-width: 731px) {
			width: 60%;
			margin: auto;
		}
	}
}

@media screen and (max-width: 731px) {
	.swiper-slide {
		width: 90%;
	}

	.w-75-mobile {
		width: 75% !important;
	}

	ul.dropdown-menu.show {
		border: none !important;
	}

	a.nav-link {
		outline: none !important;
	}
}

.form-pubblicazioni {

	select,
	button,
	input {
		font-size: 0.9rem;
	}
}

@media screen and (min-width: 1200px) {
	.w-xl-75 {
		width: 75%;
	}
}

@supports (-moz-appearance: none) {
	nav.navbar {
		background-color: white;
	}
}

.virgola:not(:last-child):after {
	content: ',';
}

iframe {
	max-width: 100%;
}

.see-all {
	font-size: 0.75rem;
}

.searchwp-modal-form-trigger-el {
	color: $c_black;
}

.tease a {
	color: $c_black;
	font-weight: 700;
	text-decoration: none;
}

.tease p a {
	display: block;
	margin-top: 1rem;
}

.searchwp-modal-form-default .searchwp-modal-form__content {
	#searchsubmit {
		font-size: 0.75rem;
	}

	@media screen and (max-width: 768px) {
		max-width: 95%;
		margin: auto;
		font-size: 0.9rem;
		padding: 4em 1em !important;

		#searchsubmit {
			font-size: 0.85rem;
		}
	}
}

.searchwp-modal-form__container:focus-visible {
	outline: none;
}

.asterisk {
	color: $c_red;
}

.mc-list {
	list-style: none;
}

.mc_fieldset,
.mc-list {
	input {
		margin-right: .5rem;
	}
}









@media screen and (max-width: 768px) {

	.input-group,
	.input-group button {
		font-size: .7rem;
	}
}

nav {
	&#top-bar {
		position: relative;
		font-size: 0.8rem;
		background-color: $c_black;
		padding: 0.5rem 2vw;
		// z-index: 100;

		// @MEDIA MOBILE
		@media screen and (max-width: 812px) {
			display: none;
		}

		a {
			color: $c_grey;
			text-decoration: none;

			&:hover {
				color: lighten($c_grey, 10);
			}
		}

		.social-icons {
			font-size: 1.2rem;
		}

		.topbar-search {
			border: 1px solid $c_grey;
			padding-left: 0.75rem;
		}

		#search-btn {
			// font-size: 0.7em;
			padding: 0;
			width: 2rem;
		}

		.lang-switch {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			.dropdown {
				.dropdown-toggle {
					text-transform: uppercase;
					color: $c_black;
					border: 1px solid #cdcdcd;

					background-color: #efefef;
					border-radius: 6px;
					padding: 6px;
					text-align: center;

					// font-size: 0.8rem;
					width: 2rem;

					&.show {
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}

					&::after {
						content: none;
					}
				}

				.dropdown-menu {
					min-width: unset;
					text-transform: uppercase;
					border: 1px solid #ccc;
					border-radius: 6px;
					background: #fff;
					// font-size: 0.8rem;
					margin-top: 0;
					border-top: 0;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					width: 2rem;

					>li {
						>a.dropdown-item {
							text-align: center;
							padding: 0;
							text-transform: uppercase;
							padding: 0;
							color: $c_black;

							&:focus {
								background-color: #ccc;
							}
						}
					}
				}
			}
		}
	}
}


// Grafici template

.page-template-template-grafici iframe {
	min-height: 500px !important;
}

.chart-col {
	.caption {
		font-size: 0.85rem;
		font-style: italic;
		opacity: 0.8;
	}
}

.back-top-container {
	bottom: 1rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;

	@media screen and (max-width: 731px) {
		bottom: 4rem;
	}
}

.back-to-top-text {
	opacity: 0;
	visibility: hidden;
	color: $c_grey;
}

.back-to-top {
	--size: 40px;
	width: var(--size);
	height: var(--size);
	opacity: 0;
	visibility: hidden;
	border-radius: 50%;
	background-color: $c_blueish;
	border: 2px solid $c_grey;
	color: $c_grey;
	transition: 0.2s;

	&:hover {
		border: 2px solid $c_blueish;
		color: $c_grey;
	}

	.sub-chevron {
		transform: rotate(180deg);

		.icon {
			font-size: 0.8rem;
		}
	}
}

.back-to-top--is-visible {
	visibility: visible;
	opacity: 1;
}

.toc-title {
	@media screen and (min-width: 1200px) {
		margin-top: 8rem;
	}

	margin-top: 4rem;
}

ul#toc {
	list-style: none;
	padding-left: 0;
}

#dynamic-toc #toc li a h5 {
	color: inherit;
}

.page-template-template-grafici .nohero-title .paragraph-content {
	font-size: 1.2rem;
	font-weight: 500;
}