@mixin bg-entry($name, $bg_color, $text_color: inherit) {
  &[data-bg="#{$name}"] {
    background-color: $bg_color;
    color: $text_color;
  }
}

@mixin color-entry($name, $color) {
  &[data-color="#{$name}"] {
    --c-accent: #{$color};

    .accent-color {
      color: $color;
    }

    h3 {
      color: $color;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: $color;
    }

    .slider {
      .title-line {
        background-color: $color;
      }

      .read-more {
        color: $color;
      }
    }
  }
}
